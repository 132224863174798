import { ADD_MESSAGE } from "@/graphql/mutations/addMessage";
import { useMutation } from "@apollo/client";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form"

type FormData = {
  message: string;
  guest: string;
}

const useWriteUs = () => {

  const [addMessage] = useMutation(ADD_MESSAGE);
  const [responseMessage, setResponseMessage] = useState<String>('');
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const theme = useTheme();
  const matchesTab = useMediaQuery(theme.breakpoints.down("tablet"));

  const { 
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      guest: '',
      message: ''
    }
  });

  const handleClose = () => {
    setOpenSnack(false);
    setResponseMessage('');
  }

  const onSubmit: SubmitHandler<FormData> = (values) => {
    (values)
    addMessage({
      variables: {
        message: values.message,
        author: values.guest
      },
      onCompleted: (data) => {
        if(data.addMessage) {
          setResponseMessage('Recibimos tu mensajito, Gracias por tus palabras');
          setOpenSnack(true);
          reset();
        } else {
          setResponseMessage('No pudimos recibir tu mensajito, Intenta de nuevo');
          setOpenSnack(true);
          reset();
        }
      }
    })
  }

  return {
    matchesTab,
    control,
    handleSubmit,
    handleClose,
    errors,
    onSubmit,
    responseMessage,
    openSnack,
  }
}

export default useWriteUs