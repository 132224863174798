import Box from "@mui/material/Box";

import { Messages } from "@/interface/message";
import { poiret, handlee } from "@/utils/fonts";
import styles from "../styles/ApprovedMesssage.module.css";

const ApprovedMessages = ({ messages }: { messages: Messages[] }) => {
  return (
    <Box className={styles.glass}>
      {messages &&
        messages.map((message, index) => {
          return (
            <p
              key={message.id.toString()}
              className={
                index % 2 > 0
                  ? `${styles.movingText} ${poiret.className}`
                  : `${styles.movingText1} ${handlee.className}`
              }
            >
              {" "}
              <q>{message.message};</q> -{message.author}
            </p>
          );
        })}
    </Box>
  );
};

export default ApprovedMessages;
