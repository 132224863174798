import { gql } from '@apollo/client';

export const ADD_MESSAGE = gql`
  mutation AddMessage (
    $message: String!
    $author: String!
  ) {
    addMessage(
      message: $message
      author: $author
    ) {
      id
      approved
      author
      createdAt
      message
    }
  }
`