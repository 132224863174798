import ApprovedMessages from "@/components/ApprovedMessages";
import useWriteUs from "@/hooks/useWriteUs";
import { Messages } from "@/interface/message";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { SlideProps } from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import { Controller } from "react-hook-form";
import { Background, Parallax } from "react-parallax";

import classes from "../../styles/WriteUs.module.css";
import { handlee } from "@/utils/fonts";
import useBreakpoints from "@/hooks/useBreakpoints";

type TransitionProps = Omit<SlideProps, "direction">;

const TransitionUp = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
};

const WriteUs = ({ messages }: { messages: Messages[] }) => {
  const {
    control,
    errors,
    handleSubmit,
    onSubmit,
    openSnack,
    responseMessage,
    matchesTab,
    handleClose,
  } = useWriteUs();

  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  return (
    <Parallax strength={300}>
      <Background className={"parallaxImage"}>
        <Image
          alt="Write Us parallax Image"
          src={
            smartphone || mobile || tablet
              ? "/canada_mobile.webp"
              : "/canada_desk.webp"
          }
          loading={"lazy"}
          fill
          quality={100}
          style={{
            objectFit: "cover",
          }}
        />
      </Background>

      <Box
        height={"78vh"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction={"row"}
            justifyContent={matchesTab ? "space-around" : "flex-end"}
            pt={"3vh"}
            pr={"3vw"}
          >
            <Stack height={"30vh"}>
              <Typography
                mb={"2vh"}
                // fontFamily={"creveland-midleton"}
                className={handlee.className}
                color={"var(--color-primary-3)"}
                letterSpacing={2}
                sx={{
                  fontWeight: "600 !important",
                }}
              >
                Dejanos tu mensajito
              </Typography>
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-message"
                    label=""
                    variant="outlined"
                    multiline
                    rows={4}
                    inputProps={{
                      "aria-label": "guest",
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "15px",
                        borderColor: "primary",
                      },
                      "aria-label": "message",
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        display: "none",
                      },
                    }}
                    sx={{
                      width: matchesTab ? "100%" : " 40vw",
                      "& > .MuiInputBase-root > fieldset": {
                        border: "2px solid var(--font-color-100)",
                      },
                      "& > .MuiInputBase-root:hover > fieldset": {
                        border: "2px solid var(--font-color-100)",
                      },
                    }}
                  />
                )}
              />

              <Typography
                my={"2vh"}
                className={handlee.className}
                color={"var(--color-primary-3)"}
                letterSpacing={2}
                sx={{
                  fontWeight: "600 !important",
                }}
              >
                Dinos quien nos escribe
              </Typography>
              <Controller
                name="guest"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-guest"
                    label=""
                    variant="outlined"
                    inputProps={{
                      "aria-label": "guest",
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "15px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        display: "none",
                      },
                    }}
                    sx={{
                      width: matchesTab ? "100%" : "40vw",
                      "& > .MuiInputBase-root > fieldset": {
                        border: "2px solid var(--font-color-100)",
                      },
                      "& > .MuiInputBase-root:hover > fieldset": {
                        border: "2px solid var(--font-color-100)",
                      },
                    }}
                  />
                )}
              />
              <Button
                id={"submitMessage"}
                aria-label={"submit"}
                type={"submit"}
                variant={"contained"}
                className={`${classes.submitButton} ${handlee.className}`}
              >
                Enviar mensaje
              </Button>
            </Stack>
          </Stack>
        </form>

        <ApprovedMessages messages={messages} />
      </Box>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {responseMessage}
        </Alert>
      </Snackbar>
    </Parallax>
  );
};

export default WriteUs;
